
.App-bg {
  background-color: #F8F8EB;
}

.home-container {
  background-color: red; /* Set your desired background color here */
}

.App-navbar-bg {
  background-color: #002B76;
}

.App-text-color {
  color: 'red';
}

.roboto-font {
  font-family: 'Roboto', sans-serif;
}

.logo-b {
  width: 15%;
}

button.navbar-toggler.collapsed {
  border-color: #F8F8EB;
}

.navbar-toggler:focus {
  border-color: #F8F8EB;
}

.navbar-toggler-icon {
  background-color: #F8F8EB;
}

button.btn-blue.btn.btn-primary.btn-md {
  background-color: #002B76;
  font-size: 20px;
}

.home-intro {
  display: flex;
  align-items: center;
  font-size: 25px;
  text-align: center; 
  border-style: solid; 
  padding-top: 20px;
  border-color: #002B76
}

.recruiter-box {
  align-items: center;
  font-size: 18px;
  border-style: solid; 
  border-color: #002B76;
  padding-top: 20px;
 
}

.section-title {
  font-size: 25px;
  text-align: center; 
 }

 .sub-title {
  font-size: 25px;
 }

 .content {
  min-height: 100vh; /* 100vh = 100% viewport height */
}



